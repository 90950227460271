import React from 'react'
import styled from 'styled-components'
import 'normalize.css'
import Nav from './Nav'
import Footer from './Footer'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import SEO from './SEO'
import FormStyles from '../styles/FormStyles'

const ContentStyles = styled.div`
  background: white;
`

export default function Layout({ children }) {
  return (
    <>
      <SEO />
      <GlobalStyles />
      <Typography />
      <FormStyles />
      <ContentStyles>
        {children}
        <Footer />
      </ContentStyles>
    </>
  )
}
