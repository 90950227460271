import React from 'react'
import styled from 'styled-components'
import Logo from '../assets/images/inline/magee-bnb-logo.svg'
// import Facebook from '../assets/images/inline/social-facebook.svg'
// import LinkedIn from '../assets/images/inline/social-linkedin.svg'
// import Twitter from '../assets/images/inline/social-twitter.svg'

const FooterStyles = styled.footer`
  background-color: var(--blue);
  padding: var(--section-spacing) 0;
  display: grid;
  gap: 4rem;
  justify-content: center;
  .logo {
    margin: 0 auto;
  }
  .social {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  .contact {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
`

export default function Footer() {
  return (
    <FooterStyles>
      <Logo className="logo" />
      {/* <div className="social">
        <a href="http://www.facebook.com" title="Carebeds Facebook page">
          <Facebook />
        </a>
        <a href="http://www.twitter.com" title="Carebeds Twitter page">
          <Twitter />
        </a>
        <a href="http://www.linkedin.com" title="Carebeds LinkedIn page">
          <LinkedIn />
        </a>
      </div>
      <div className="contact">
        <a href="mailto:info@carebeds.org">info@carebeds.org</a>
        <a href="tel:08006129266">0800 612 9266</a>
      </div> */}
      <span className="text-center">
        &copy; Magee B&amp;B {new Date().getFullYear()}
      </span>
    </FooterStyles>
  )
}
