import { createGlobalStyle } from 'styled-components'

const FormStyles = createGlobalStyle`
  label {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  input[type="text"],
  input[type="password"],
  input[type="url"],
  input[type="tel"],
  input[type="search"],
  input[type="number"],
  input[type="datetime"],
  input[type="email"],
  textarea {
    display: block;
    font-size: 2rem;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    margin-top: 0.4rem;
    border: 1px solid #fff;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 2rem;
  }
  .honey {
    display: none !important;
  }
`

export default FormStyles
