import React from 'react'
import styled from 'styled-components'
import Logo from '../assets/images/inline/magee-bnb-logo.svg'

const NavStyles = styled.nav`
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  align-items: center;
  .logo {
    justify-self: start;
  }
  .links {
    margin: 0;
    padding: 0;
    list-style: none;
    justify-self: end;
    display: grid;
    grid-template-columns: repeat(1, max-content);
    gap: 2rem;
  }
  @media (max-width: 991px) {
    .logo {
      margin-top: 3rem;
      margin-bottom: 3rem;
      justify-self: center;
    }
    .links {
      justify-self: center;
    }
  }
`

export default function Nav() {
  return (
    <NavStyles>
      <Logo className="logo" />
      <ul className="links">
        <li>
          <a
            href={`tel:${process.env.GATSBY_CONTACT_PHONE.replace(/ /g, '')}`}
            className="button white"
            title={`Call ${process.env.GATSBY_CONTACT_PHONE}`}
          >
            {process.env.GATSBY_CONTACT_PHONE}
          </a>
        </li>
      </ul>
    </NavStyles>
  )
}
