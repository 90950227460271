import { createGlobalStyle } from 'styled-components'

import fontBookWoff from '../assets/fonts/FuturaPT-Book.woff'
import fontBookWoff2 from '../assets/fonts/FuturaPT-Book.woff2'
import fontMediumWoff from '../assets/fonts/FuturaPT-Medium.woff'
import fontMediumWoff2 from '../assets/fonts/FuturaPT-Medium.woff2'

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'FuturaPT';
    src:  url(${fontBookWoff2}) format('woff2'),
          url(${fontBookWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'FuturaPT';
    src:  url(${fontMediumWoff2}) format('woff2'),
          url(${fontMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  html {
    font-family: FuturaPT, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
    font-weight: 300;
  }
  p {
    line-height: 1.4em;
  }
  h1 {
    font-size: 5.4rem;
  }
  h2 {
    font-size: 5.4rem;
  }
  h3 {
    font-size: 2rem;
    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    color: var(--blue);
    font-weight: 500;
    line-height: 1.2em;
  }
  a {
    color: var(--blue);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .text-center {
    text-align: center;
  }
`

export default Typography
