import { createGlobalStyle } from 'styled-components'
import ldColor from '../utilities/lightenDarkenColor'

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #2E4057;
    --blue-hover: ${ldColor(`#2E4057`, -12)};
    --background-light: #f5f5f5;
    --disabled-color: #999;
    --complete-color: #555;
    --text-color: #999;
    --base-font-size: 10px;
    --gap-layout: 2rem;
    --gap-items: 2rem;
    --section-spacing: 8rem;
    --panel-border-radius: 6px;
    --input-border-radius: 4px;
    @media (max-width: 767px) {
      --base-font-size: 8px;
      --section-spacing: 4rem;
    }
  }
  html {
    font-size: var(--base-font-size);
    scroll-behavior: smooth;
  }

  body {
    font-size: 2rem;
    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }

  .button, button {
    display: inline-block;
    background: var(--blue);
    color: white;
    border: 0;
    padding: 2rem 2.4rem;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: var(--blue-hover);
      text-decoration: none;
    }
    &:disabled, &[disabled] {
      background: var(--disabled-color);
      &.complete {
        background: var(--complete-color);
        cursor: default;
      }
    }
    &.white {
      color: var(--blue);
      background: #fff;
      &:hover {
        background: #eee;
      }
    }
  }

  hr {
    border: 0;
    margin: 0;
    height: 1px;
    background-color: var(--blue-trans-15);
  }

  img {
    max-width: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    ${'' /* image-rendering: pixelated; */}
  }
`

export default GlobalStyles
